.hoox-clinical-statistics {
    padding: 40px 0;
    background: var(--hoox-color-grey);
}
.hoox-clinical-statistics__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.hoox-clinical-statistics__content {
    background: var(--hoox-color-lilac);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 18px 18px 32px;
}
.hoox-clinical-statistics__title {
    margin-bottom: 32px;
    max-width: 250px;
}
.hoox-clinical-statistics__image {
    flex: 1;
}
.hoox-clinical-statistics__icon {
    display: none;
}
.hoox-clinical-statistics__props {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    align-items: center;
}
.hoox-clinical-statistics__prop {
    display: flex;
    gap: 8px;
    align-items: center;
}
.hoox-clinical-statistics__prop-statistics {
    font-style: italic;
    font-size: 20px;
    line-height: 20px;
}
.hoox-clinical-statistics__prop-description {
    font-size: 14px;
}
.hoox-clinical-statistics__disclaimer {
    font-size: 12px;
    line-height: 15px;
    color: var(--hoox-color-slate-brown);
    margin-top: 27px;
    max-width: 216px;
}

.hoox-clinical-statistics__media {
    height: 320px;
}

@media (min-width: 767px) {
        .hoox-clinical-statistics {
        padding: 86px 0;
        min-height: 778px;
    }
    .hoox-clinical-statistics__wrapper {
        flex-direction: row;
        height: 600px;
        position: relative;
    }
    .hoox-clinical-statistics__title {
        max-width: 320px;
    }
    .hoox-clinical-statistics__content {
        flex: 1;
        height: 100%;
        padding: 0;
        padding-top: 160px;
    }
    .hoox-clinical-statistics__media {
        flex: 1;
        height: 100%;
    }
    .hoox-clinical-statistics__disclaimer {
       position: absolute;
       left: 50%;
       transform: translateX(-50%);
       bottom: -46px;
       max-width: unset;
       font-size: 14px;
    }
    .hoox-clinical-statistics__prop-statistics {
    font-size: 32px;
    line-height: 32px;
}
.hoox-clinical-statistics__prop-description {
    font-size: 16px;
}
.hoox-clinical-statistics__icon {
    display: block;
    position: absolute;
    left: 30px;
    bottom: 30px;
}
}